@tailwind base;
@tailwind components;
@tailwind utilities;

div[vaul-drawer] > :first-child {
  display: none;
}

html,
body,
#__next,
#__next > main {
  height: 100dvh;
  scroll-behavior: smooth;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.select-content {
  width: var(--radix-select-trigger-width);
}

.select-content-inner {
  width: calc(var(--radix-select-trigger-width) - 34px);
}
